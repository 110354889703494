<script>
export default {
    name: 'KToolbar',
    props: {
        showArrowBack: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        extended: Boolean,
    },
    computed: {},
}
</script>

<template>
    <div class="k-toolbar">
        <div class="k-toolbar__top px-4 px-sm-6 px-md-10">
            <slot name="back">
                <v-tooltip max-width="300" bottom v-if="showArrowBack">
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" class="indigo lighten-5 mr-4" to="/">
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                    </template>
                    <span class="text-capitalize">{{ 'home' | translate }}</span>
                </v-tooltip>
            </slot>
            <div class="k-toolbar__top__title">
                <slot name="title"></slot>
            </div>
            <v-spacer />
            <slot name="right"></slot>
        </div>
        <div v-if="$slots['default']" class="k-toolbar__bottom px-4 px-sm-6 px-md-10">
            <slot />
        </div>
    </div>
    <!-- <v-toolbar :extended="extended" class="k-toolbar">
        <slot name="back">
            <v-tooltip max-width="300" bottom v-if="showArrowBack">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" class="ml-0 mr-4" to="/">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                </template>
                <span class="text-capitalize">{{ 'home' | translate }}</span>
            </v-tooltip>
        </slot>
        <v-toolbar-title class="text-capitalize">
            <slot name="title"></slot>
        </v-toolbar-title>
        <v-spacer />
        <slot />
        <template v-if="extended" #extension><slot name="extension"></slot></template>
    </v-toolbar> -->
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.k-toolbar {
    background-color: transparent;

    &__top {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            font-size: 24px;

            &::first-letter {
                text-transform: uppercase;
            }

            *::first-letter {
                text-transform: uppercase;
            }
        }
    }
}
</style>
