export const PROJECT_TYPES = {
    LOYALTY: 'loyalty',
    COUPON: 'coupon',
    TICKET: 'ticket',
    VOUCHER: 'voucher',
    SUBSCRIPTION: 'service',
    BOOKING: 'booking',
    TRANSPORT: 'transport',
}

export const PROJECT_SUBTYPES = {
    EVENT: 'event',
    ADMISSION: 'admission',
    BUNDLE: 'bundle',
    STAMP: 'stamp',
    MEMBER: 'member',
    MONETARY: 'monetary',
    PERCENT: 'percent',
    FIXED: 'fixed',
    VOUCHER: 'voucher',
    KAISUKEN: 'kaisuken',
    MEETING: 'meeting_pass',
    HOTEL: 'hotel_pass',
    BASIC: 'basic',
    COMMUTER: 'commuter',
    LIFT: 'lift',
    MULTIRIDE: 'multiride',
}

export const PROJECT_WITH_SUBTYPES = {
    COUPON_VOUCHER: {
        name: 'voucher',
        type: PROJECT_TYPES.COUPON,
        subtype: PROJECT_SUBTYPES.VOUCHER,
        description: {
            en: 'Use this pass type to enhance customer loyalty and encourage repeat visits. By providing appealing benefits such as services or complimentary products, you can motivate customers to return.',
            ja: '紛失や使い忘れを減らし、リピーターの集客を実現する引換券は、効果的なパスタイプです。サービスや無料商品など魅力的な特典を提供することで、顧客のロイヤルティを高め、リピーターを増やすことができます。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
    },
    COUPON_PERCENT: {
        name: 'percentage discount coupon',
        type: PROJECT_TYPES.COUPON,
        subtype: PROJECT_SUBTYPES.PERCENT,
        description: {
            en: 'This pass type can be used to effectively promote specific products or services by offering an attractive percentage discount on the regular price.',
            ja: '特定の商品やサービスを促進するために、通常価格から一定の割合（10%オフや50%オフなど）を割引が適用されるパスタイプです。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
    },
    COUPON_FIXED: {
        name: 'fixed discount coupon',
        type: PROJECT_TYPES.COUPON,
        subtype: PROJECT_SUBTYPES.FIXED,
        description: {
            en: 'This pass type offers a discount for a specific amount. By using fixed discount coupon in promotion campaigns, special events, and new product releases, you can increase store visits and sales.',
            ja: '値引券は、一定の金額分の割引が受けられるパスタイプです。セールや特別イベント、新商品の発売などに活用することによって来店頻度および売上の拡大が期待できます。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
    },
    COUPON_MONETARY: {
        name: 'monetary voucher',
        type: PROJECT_TYPES.COUPON,
        subtype: PROJECT_SUBTYPES.MONETARY,
        description: {
            en: 'This pass type can be used as a form of payment with a specific amount. It can also serve as a gift card or prepaid card.',
            ja: '決済手段として使える、金額が記載されたパスタイプです。このタイプはギフトカードやプリペイドカードとしての活用も可能です。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
    },
    VOUCHER_KAISUKEN: {
        name: 'multi-use voucher',
        type: PROJECT_TYPES.VOUCHER,
        subtype: PROJECT_SUBTYPES.KAISUKEN,
        description: {
            en: 'This pass allows users to access a service or product a set number of times. For instance, a fitness club pass provides discounted gym visits or class attendance. Likewise, a dining pass for a cafe or restaurant offers a reduced price for a specified number of meals.',
            ja: '特定のサービスや商品を一定回数分利用できるパスタイプです。例えば、フィットネスクラブでのトレーニング回数券では、一定回数のジム利用やクラス受講など、通常の料金よりもお得になることがあります。また、カフェやレストランでの飲食回数券では、一定回数の食事を割引価格で提供するなどの運用も可能です。',
        },
        pictogram: '/images/stampcard-pictogram-indigo.png',
        createLink: '/products/vouchers/create',
    },
    LOYALTY_STAMP: {
        name: 'stamp card',
        type: PROJECT_TYPES.LOYALTY,
        subtype: PROJECT_SUBTYPES.STAMP,
        description: {
            en: 'Offer your customers digital punch cards worldwide, while creating a standout loyalty program with our digital design tools. Enhance your brand presence on mobile with Apple Wallet and Google Wallet apps!',
            ja: '顧客が来店毎にスタンプを取得できるデジタルスタンプカードのプロジェクトタイプです。QRコードやURLを使いオンラインもしくはオフラインで配布可能です。',
        },
        pictogram: '/images/stampcard-pictogram-indigo.png',
        createLink: '/products/stamps/create',
    },
    LOYALTY_MEMBER: {
        name: 'membership card',
        type: PROJECT_TYPES.LOYALTY,
        subtype: PROJECT_SUBTYPES.MEMBER,
        description: {
            en: 'Build relation, add points and contact your clients via digital membership cards.',
            ja: 'メンバーズカードなどの会員証を作成できます。会員証を通じて顧客にポイントも付与することができます。',
        },
        pictogram: '/images/generic-card-pictogram-indigo.png',
        createLink: '/products/membership/create',
    },
    SUBSCRIPTION: {
        name: 'subscription pass',
        type: PROJECT_TYPES.SUBSCRIPTION,
        subtype: null,
        description: {
            en: 'Subscribe your clients to free or paid recurring services or products.',
            ja: 'サービスを定額課金モデルでの販売・会員証明を可能にするプロジェクトタイプです。利用回数制限、利用店舗縛り、入会金など初期費用、利用日時制限など設定が可能です。',
        },
        pictogram: '/images/generic-card-pictogram-indigo.png',
        createLink: '/products/subscriptions/create',
    },
    TICKET_ADMISSION: {
        name: 'admission ticket',
        type: PROJECT_TYPES.TICKET,
        subtype: PROJECT_SUBTYPES.ADMISSION,
        description: {
            en: 'This is a project type for issuing digital tickets for events, facilities and other purposes. The tickets can be sold or distributed for free or at a cost, depending on whether a reservation is required or not.',
            ja: '施設やイベントなどのデジタルチケットを作成するためのプロジェクトタイプです。チケットは、予約の有無および無料もしくは有料で販売や配布することが可能です。',
        },
        pictogram: '/images/ticket-pictogram-indigo.png',
        createLink: '/products/tickets/create',
    },
    TICKET_BUNDLE: {
        name: 'bundle ticket',
        type: PROJECT_TYPES.TICKET,
        subtype: PROJECT_SUBTYPES.BUNDLE,
        description: {
            en: 'This is a project type that focuses on digital passes that allow access to multiple services or entry to multiple facilities. A pass can be sold or distributed, with the option of reservation.',
            ja: '対象の複数サービスが利用できる、または複数施設への入場できるデジタルパスの発着券に特化したプロジェクトタイプです。共通券は、予約の有無および無料もしくは有料で販売や配布することが可能です。',
        },
        pictogram: '/images/ticket-pictogram-indigo.png',
        createLink: '/products/tickets/create',
    },
    BOOKING: {
        name: 'booking pass',
        type: PROJECT_TYPES.BOOKING,
        subtype: PROJECT_SUBTYPES.BASIC,
        beta: true,
        description: {
            en: 'This project type can be used for issuing reservation certificates. It can be utilized for purposes such as booking a stylist at a beauty salon or reserving rental items at a campsite.',
            ja: 'サービスや人を予約する際に予約証明書として使えるパスが発行できるプロジェクトタイプです。美容室などでの担当者の指名、キャンプ場などでのレンタルアイテムの予約などにご活用いただけます。',
        },
        pictogram: '/images/generic-card-pictogram-indigo.png',
        createLink: '/products/bookings/create',
    },
    BOOKING_HOTEL: {
        name: 'hotel pass',
        type: PROJECT_TYPES.BOOKING,
        subtype: PROJECT_SUBTYPES.HOTEL,
        beta: true,
        description: {
            en: 'This project type is designated for issuing hotel booking passes. By integrating the project with RemoteLock, it enables unmanned check-in flow at the hotel.',
            ja: '宿泊予約したことを証明するパス。RemoteLockといったスマートロックシステムと連動することでホテルの無人チェックインフローが可能になります。',
        },
        pictogram: '/images/generic-card-pictogram-indigo.png',
        createLink: '/products/bookings/create',
    },
    BOOKING_MEETING: {
        name: 'matching pass',
        type: PROJECT_TYPES.BOOKING,
        subtype: PROJECT_SUBTYPES.MEETING,
        beta: true,
        description: {
            en: 'This project type can be used for business matching reservations.',
            ja: '企業同士のマッチングの予約等の業務を支援するソリューションです。',
        },
        pictogram: '/images/generic-card-pictogram-indigo.png',
        createLink: '/products/bookings/create',
    },
    TRANSPORT_LIFT: {
        name: 'lift or ropeway ticket',
        type: PROJECT_TYPES.TRANSPORT,
        subtype: PROJECT_SUBTYPES.LIFT,
        description: {
            en: 'This project type is for issuing and selling one-way or round-trip tickets for the transport services there is only one boarding and alighting station or stop, such as for cable cars or lifts.',
            ja: 'ケーブルカーやリフトなど発着駅が１ヶ所ずつしかない場合に使われる片道・往復乗車券です。',
        },
        pictogram: '/images/ticket-pictogram-indigo.png',
        createLink: '/products/transport/create',
    },
    TRANSPORT_BASIC: {
        name: 'basic fare ticket',
        type: PROJECT_TYPES.TRANSPORT,
        subtype: PROJECT_SUBTYPES.BASIC,
        description: {
            en: 'This is a project type that enables the issuance and sale of one-way or round-trip tickets as digital passes from X station to Y station on the Z route.',
            ja: '○○○路線のX駅からY駅までの片道または往復の乗車券をデジタルパスとして発行・販売を可能にするプロジェクトタイプです。',
        },
        pictogram: '/images/ticket-pictogram-indigo.png',
        createLink: '/products/transport/create',
    },
    TRANSPORT_MULTIRIDE: {
        name: 'multi-ride ticket',
        type: PROJECT_TYPES.TRANSPORT,
        subtype: PROJECT_SUBTYPES.MULTIRIDE,
        description: {
            en: 'Use this project type to for tickets that allow unlimited boarding and alighting within a pre-determined period and route.',
            ja: 'フリーチケットのような、あらかじめ指定した期間と路線内であれば、何回でも自由に乗降できる乗車券です。',
        },
        pictogram: '/images/ticket-pictogram-indigo.png',
        createLink: '/products/transport/create',
    },
    TRANSPORT_COMMUTER: {
        name: 'commuter pass',
        type: PROJECT_TYPES.TRANSPORT,
        subtype: PROJECT_SUBTYPES.COMMUTER,
        description: {
            en: 'Use this project type to issue commuter passes or season tickets which allow unlimited use of public transportation within a specific area and time period. The commuter passes are offered at a discounted rate and are ideal for regular commuters who travel to and from work or school daily.',
            ja: 'あらかじめ決められた期間と区間内であれば、何度でも自由に乗車できる割引乗車券（通勤・通学）です。',
        },
        pictogram: '/images/ticket-pictogram-indigo.png',
        createLink: '/products/transport/create',
    },

    // Hidden projects types
    VOUCHER: {
        name: 'voucher',
        type: PROJECT_TYPES.VOUCHER,
        subtype: null,
        description: {
            en: 'Use this pass type to enhance customer loyalty and encourage repeat visits. By providing appealing benefits such as services or complimentary products, you can motivate customers to return.',
            ja: '紛失や使い忘れを減らし、リピーターの集客を実現する引換券は、効果的なパスタイプです。サービスや無料商品など魅力的な特典を提供することで、顧客のロイヤルティを高め、リピーターを増やすことができます。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
        hidden: true,
    },
    VOUCHER_BASIC: {
        name: 'voucher',
        type: PROJECT_TYPES.VOUCHER,
        subtype: PROJECT_SUBTYPES.BASIC,
        description: {
            en: 'Use this pass type to enhance customer loyalty and encourage repeat visits. By providing appealing benefits such as services or complimentary products, you can motivate customers to return.',
            ja: '紛失や使い忘れを減らし、リピーターの集客を実現する引換券は、効果的なパスタイプです。サービスや無料商品など魅力的な特典を提供することで、顧客のロイヤルティを高め、リピーターを増やすことができます。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
        hidden: true,
    },
    VOUCHER_VOUCHER: {
        name: 'voucher',
        type: PROJECT_TYPES.VOUCHER,
        subtype: PROJECT_SUBTYPES.VOUCHER,
        description: {
            en: 'Use this pass type to enhance customer loyalty and encourage repeat visits. By providing appealing benefits such as services or complimentary products, you can motivate customers to return.',
            ja: '紛失や使い忘れを減らし、リピーターの集客を実現する引換券は、効果的なパスタイプです。サービスや無料商品など魅力的な特典を提供することで、顧客のロイヤルティを高め、リピーターを増やすことができます。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
        hidden: true,
    },
    VOUCHER_MONETARY: {
        name: 'monetary voucher',
        type: PROJECT_TYPES.VOUCHER,
        subtype: PROJECT_SUBTYPES.MONETARY,
        description: {
            en: 'This pass type can be used as a form of payment with a specific amount. It can also serve as a gift card or prepaid card.',
            ja: '決済手段として使える、金額が記載されたパスタイプです。このタイプはギフトカードやプリペイドカードとしての活用も可能です。',
        },
        pictogram: '/images/coupon-pictogram-indigo.png',
        createLink: '/products/coupons/create',
        hidden: true,
    },
    // DEPRECATED in favor of TICKET_ADMISSION.
    TICKET_EVENT: {
        name: 'event ticket',
        type: PROJECT_TYPES.TICKET,
        subtype: PROJECT_SUBTYPES.EVENT,
        description: {
            en: 'This project type allows issuing digital tickets for events. Tickets can be sold or distributed for free, and it is possible to indicate whether a reservation is required or not.',
            ja: 'イベントなどのデジタルチケットを作成するためのパスタイプです。チケットは、予約の有無および無料もしくは有料で販売や配布することが可能です。。',
        },
        pictogram: '/images/ticket-pictogram-indigo.png',
        createLink: '/products/tickets/create',
        hidden: true,
    },
}
