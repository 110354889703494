import api from '@/api'
import axios from 'axios'

const state = {
    timezones: [],
}

const mutations = {
    SET_TIMEZONES(state, data) {
        state.timezones = data
    },
}

const actions = {
    createTicketProject({ dispatch, commit }, project) {
        project.type = project.type ? project.type : 'event' // fallback to event
        return dispatch('createProject', project).then(data => {
            dispatch('getUser')
            commit('SET_PROJECTS')
            return data
        })
    },
    updateTicketProject({ dispatch, commit }, project) {
        return dispatch('updateProject', project).then(data => {
            commit('SET_PROJECTS')
            return data
        })
    },

    getTimezones({ commit, dispatch }) {
        return axios
            .get('/timezones.json')
            .then(({ data }) => {
                commit('SET_TIMEZONES', data)
            })
            .catch(e => dispatch('handleError', e))
    },

    getFutureEventTickets({ dispatch }) {
        return api
            .get(`/events/future/get`)
            .then(data => {
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
