import api from '@/api'

const state = {
    list: null,
    item: null,
}

const mutations = {
    SET_CAMPAIGNS(state, data) {
        state.list = data
    },
    SET_CAMPAIGN(state, data) {
        state.item = data
    },
}

const actions = {
    getCampaigns({ commit, dispatch }, params = {}) {
        let { pagination, search, filters, sent } = params
        pagination ? delete pagination.totalItems : false
        return api
            .get('/campaigns', { params: { ...pagination, search, filters, sent } })
            .then(data => {
                commit('SET_CAMPAIGNS', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    getCampaign({ commit, dispatch }, id) {
        return api
            .get(`/campaigns/${id}`)
            .then(({ data }) => {
                commit('SET_CAMPAIGN', data)
                return data
            })
            .catch(e => dispatch('handleError', e))
    },
    createCampaign({ dispatch }, campaign) {
        delete campaign.contact_list
        return api
            .post('/campaigns', campaign)
            .then(response => {
                dispatch('getCampaigns')
                return response.data
            })
            .catch(e => dispatch('handleError', e))
    },
    updateCampaign({ dispatch }, campaign) {
        delete campaign.contact_list
        return api
            .patch(`/campaigns/${campaign.id}`, campaign)
            .then(response => response.data)
            .catch(e => dispatch('handleError', e))
    },
    sendCampaign({ dispatch }, { campaignId, protocol }) {
        const data = { protocol: protocol }
        return api
            .post(`/campaigns/${campaignId}/send`, data)
            .catch(e => dispatch('handleError', e))
    },
    scheduleCampaign({ dispatch }, { campaignId, schedule }) {
        return api
            .post(`/campaigns/${campaignId}/schedule`, schedule)
            .catch(e => dispatch('handleError', e))
    },
    deleteCampaign({ dispatch }, id) {
        return api.delete(`/campaigns/${id}`, {}).catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
