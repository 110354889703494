import api from '@/api'
import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import { calculateDesignColors } from '@/utils/colors'

const state = {
    list: [],
    active_design: {
        colors: calculateDesignColors({
            background_color: '#3F51B5',
            text_color: '#ffffff',
        }),
    },
}

const mutations = {
    RESET(state) {
        state.designs = []
        state.active_design = null
    },
    SET_DESIGNS(state, data) {
        state.list = data
    },
    SET_ACTIVE_DESIGN(state, design) {
        if (design && design.id) {
            state.active_design = {
                ...design,
            }
        } else {
            state.active_design = design
        }
    },
}

const actions = {
    getDesigns({ commit, dispatch }) {
        return api
            .get(`/designs`)
            .then(async ({ data }) => {
                const designs = Array.isArray(data) ? data : []
                commit('SET_DESIGNS', designs)
                // set first design as active if exists
                if (!designs || !designs.length) await dispatch('createDefaultsDesigns')

                dispatch('setActiveDesign')
                return designs
            })
            .catch(e => dispatch('handleError', e))
    },
    createDesign({ commit, state, dispatch }, design) {
        return api
            .post(`/designs`, { design: serializeDesign(design) })
            .then(data => {
                const savedDesign = data && data.data
                const templates = [...state.list, savedDesign]
                commit('SET_DESIGNS', templates)
                return savedDesign
            })
            .catch(e => dispatch('handleError', e))
    },
    updateDesign({ commit, state, dispatch }, design) {
        return api
            .put(`/designs/${design.id}`, { design: serializeDesign(design) })
            .then(data => {
                const savedDesign = data && data.data
                const index = state.list.findIndex(d => d.id == design.id)
                if (index !== -1) {
                    const templates = [...state.list]
                    templates.splice(index, 1, savedDesign)
                    commit('SET_DESIGNS', templates)
                }
                return savedDesign
            })
            .catch(e => dispatch('handleError', e))
    },
    deleteDesign({ commit, state, dispatch }, id) {
        return api
            .delete(`/designs/${id}`)
            .then(() => {
                const templates = state.list.filter(d => d.id !== id)
                commit('SET_DESIGNS', templates)
            })
            .catch(e => dispatch('handleError', e))
    },
    setActiveDesign({ commit, state }, data) {
        const design = data || state.list[0] || null
        commit('SET_ACTIVE_DESIGN', design)
    },
    createDefaultsDesigns({ state, dispatch }) {
        const designs = state.list
        const default_designs = getDefaultDesigns()
        const missedAmount = default_designs.length - designs.length
        if (missedAmount <= 0) return

        const sortedDefaultDesigns = default_designs.sort(design1 => {
            const isSaved1 = designs.find(
                d => d.colors && isEqual(d.colors.default, design1.colors.default)
            )
            return isSaved1 ? -1 : 1
        })

        // create missedAmount of missed designs using default settings
        const promises = []
        for (let i = designs.length; i < sortedDefaultDesigns.length; i++) {
            let defaultDesign = sortedDefaultDesigns[i]
            promises.push(
                dispatch('createDesign', { ...defaultDesign, name: `design-${Date.now()}-${i}` })
            )
        }

        return Promise.all(promises)
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}

// omits all extra fields in request
function serializeDesign(design) {
    return pick(design, ['id', 'name', 'colors'])
}

function getDefaultDesigns() {
    return [
        { colors: { background_color: '#3F51B5', text_color: '#ffffff' } },
        { colors: { background_color: '#B53FB3', text_color: '#ffffff' } },
        { colors: { background_color: '#3FB57A', text_color: '#ffffff' } },
        { colors: { background_color: '#B53F3F', text_color: '#ffffff' } },
        { colors: { background_color: '#1A2540', text_color: '#ffffff' } },
        { colors: { background_color: '#FFB140', text_color: '#ffffff' } },
    ].map(d => ({
        ...d,
        colors: {
            ...calculateDesignColors(d.colors),
            default: d.colors,
            modified: false,
        },
    }))
}
