<script>
export default {
    computed: {
        theme() {
            return 'default'
        },
        // vuetify <v-content/> resizing works better if <footer /> has fied height
        height() {
            if (this.$vuetify.breakpoint.smAndDown) return 135
            return 50
        },
        lang_index() {
            switch (this.$root.lang) {
                case 'en':
                    return 0
                case 'pl':
                    return 2
                default:
                    return 1
            }
        },
    },
    methods: {
        changeTheme(theme) {
            this.$root.changeTheme(theme)
        },
    },
}
</script>

<template>
    <div class="page-footer px-4 px-sm-6 px-md-10">
        <div class="d-flex align-center text-body-2 grey--text text--darken-2">
            <a href="https://kinchaku.com">© Kinchaku</a>
            .
            <div class="ml-2 d-sm-inline-block">All rights reserved.</div>
        </div>
        <div class="d-flex align-center">
            <v-btn
                @click="$emit('help')"
                :text="$vuetify.breakpoint.smAndUp"
                :icon="$vuetify.breakpoint.xsOnly"
            >
                <k-icon class="grey--text text--darken-1">mdi-message-alert-outline</k-icon>
                <span class="ml-2 hidden-xs-only">{{ $t('report a problem') }}</span>
            </v-btn>
            <v-menu offset-y top left>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip max-width="300" left>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                id="btn-language"
                                class="ml-sm-2"
                                icon
                                v-on="{ ...tooltip, ...menu }"
                            >
                                <v-icon>language</v-icon>
                            </v-btn>
                        </template>
                        <span>Change language</span>
                    </v-tooltip>
                </template>
                <v-list class="py-0">
                    <v-list-item-group :value="lang_index" color="primary">
                        <v-list-item @click="$emit('language', 'en')">
                            <v-list-item-icon>
                                <i class="flag-icon flag-icon-gb" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>English</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="$emit('language', 'ja')">
                            <v-list-item-icon>
                                <i class="flag-icon flag-icon-jp" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>日本語</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import 'vuetify/src/styles/styles.sass';

.page-footer {
    background-color: map-get($shades, 'white');
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid map-get($grey, 'lighten-2');
    height: 64px;
}
</style>
