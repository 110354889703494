<script>
export default {
    props: {
        columns: {
            type: Array,
            required: true,
        },
        iconColor: String,
        tableId: String,
    },
    data() {
        return {
            showColumnsDialog: false,
            localColumns: [],
        }
    },
    mounted() {
        this.restoreActiveColumns()
    },
    watch: {
        columns() {
            this.restoreActiveColumns()
        },
    },
    methods: {
        restoreActiveColumns() {
            let savedColumns

            if (this.tableId) {
                try {
                    savedColumns = JSON.parse(localStorage.getItem(this.tableId))
                } catch (error) {
                    // eslint-disable-next-line
                    console.warn(error)
                }
            }

            if (savedColumns) {
                this.localColumns = this.columns.map(column => ({
                    ...column,
                    active: savedColumns[column.value] || true,
                }))
            } else {
                this.localColumns = this.columns.map(column =>
                    Object.prototype.hasOwnProperty.call(column, 'active')
                        ? column
                        : { ...column, active: true }
                )
            }

            const activeColumns = this.localColumns.filter(column => column.active)

            this.$emit('change', activeColumns)
        },
        save() {
            const activeColumns = this.localColumns.filter(column => column.active)

            this.saveConfigLocal()
            this.$emit('change', activeColumns)
            this.showColumnsDialog = false
        },
        onSelect(column) {
            column.active = !column.active
        },
        saveConfigLocal() {
            if (!this.tableId) return

            localStorage.setItem(
                this.tableId,
                JSON.stringify(
                    this.localColumns.reduce((columns, column) => {
                        columns[column.value] = column.active
                        return columns
                    }, {})
                )
            )
        },
    },
}
</script>

<template>
    <div class="d-inline-block">
        <v-tooltip max-width="300" bottom>
            <template #activator="{ on }">
                <v-btn
                    icon
                    dense
                    v-on="on"
                    :color="iconColor"
                    open-delay="300"
                    @click="showColumnsDialog = true"
                >
                    <v-icon>view_column</v-icon>
                </v-btn>
            </template>
            <span class="text-capitalize">
                {{ $t('manage columns') }}
            </span>
        </v-tooltip>
        <k-dialog persistent scrollable v-model="showColumnsDialog" width="500">
            <v-card>
                <v-card-title class="text-h6 font-weight-regular text-capitalize pb-0">
                    <span>
                        {{ $t('edit table columns') }}
                    </span>
                    <v-spacer />
                    <v-btn text small icon @click="showColumnsDialog = false" class="ma-0">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text style="max-height: 75vh">
                    <v-list>
                        <v-list-item
                            @click="onSelect(column)"
                            v-for="column in localColumns"
                            :key="column.key"
                            :disabled="column.required"
                        >
                            <v-list-item-action>
                                <v-checkbox :input-value="column.active" readonly hide-details />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="first-capitalize">
                                    {{ column.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-card-actions class="px-4 pb-4">
                    <v-spacer />
                    <v-tooltip max-width="300" bottom class="mb-0">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" text class="primary" @click="save">
                                <span>{{ $t('apply') }}</span>
                            </v-btn>
                        </template>
                        <span>
                            {{ $t('close') }}
                        </span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </k-dialog>
    </div>
</template>
