import api from '@/api'

const state = {
    list: null,
    pos_list: [],
    passes: {},
    passes_sum: null,
}

const mutations = {
    RESET(state) {
        state.list = []
        state.passes = {}
        state.passes_sum = null
    },
    SET_POS_PRODUCTS(state, data) {
        state.pos_list = data
    },

    SET_PRODUCT_PASSES(state, data) {
        state.passes = data
    },
    SET_PASSES_SUM(state, data) {
        state.passes_sum = data
    },
}

const actions = {
    getPosProducts({ commit, dispatch }) {
        return api
            .get(`/products/pos`)
            .then(({ products }) => {
                commit('SET_POS_PRODUCTS', products)
                return products
            })
            .catch(e => dispatch('handleError', e))
    },

    getSubscriptinPlansStats({ dispatch }, { id, venue, year }) {
        return api
            .get(`/subscriptions/${id}/stats`, { params: { venue, year } })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },

    getProductsList({ dispatch }, { type = null, search = '' }) {
        return api
            .post('/products/list/valid', { type, search })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
