import api from '@/api'

const state = {
    list: null,
}

const mutations = {}

const actions = {
    createVoucher({ dispatch }, project) {
        project.type = 'voucher'
        return dispatch('createProject', project).then(data => {
            dispatch('getUser')
            return data
        })
    },
    updateVoucher({ dispatch }, project) {
        return dispatch('updateProject', project).then(data => {
            return data
        })
    },

    getVouchersPlans({ dispatch }) {
        return api
            .get(`/vouchers/plans/get`)
            .then(data => data)
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}
