<script>
import { camelCase } from 'lodash'
import {
    mdiMessageAlertOutline,
    mdiViewDashboardOutline,
    mdiViewGridPlusOutline,
    mdiAccountMultipleOutline,
    mdiMapMarkerOutline,
    mdiRadar,
    mdiReceiptTextOutline,
    mdiBullhornOutline,
    mdiPaletteSwatchOutline,
    mdiCogOutline,
    mdiCashMultiple,
    mdiBookOpenPageVariantOutline,
    mdiTextBoxOutline,
    mdiSecurity,
    mdiFileDocumentOutline,
    mdiMagnify,
    mdiAccountCircleOutline,
    mdiCreation,
} from '@mdi/js'

export default {
    name: 'KIcon',
    computed: {
        icons_list() {
            return {
                mdiMessageAlertOutline,
                mdiViewDashboardOutline,
                mdiViewGridPlusOutline,
                mdiAccountMultipleOutline,
                mdiMapMarkerOutline,
                mdiRadar,
                mdiReceiptTextOutline,
                mdiBullhornOutline,
                mdiPaletteSwatchOutline,
                mdiCogOutline,
                mdiCashMultiple,
                mdiBookOpenPageVariantOutline,
                mdiTextBoxOutline,
                mdiSecurity,
                mdiFileDocumentOutline,
                mdiMagnify,
                mdiAccountCircleOutline,
                mdiCreation,
            }
        },
        icon() {
            const iconText = camelCase(this.$slots.default[0].text)
            return this.icons_list[iconText] || iconText
        },
    },
}
</script>

<template>
    <v-icon v-bind="$attrs" v-on="$listeners">{{ icon }}</v-icon>
</template>
