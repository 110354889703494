import withRBAC from './RBAC'

const routes = [
    {
        path: '/*/en',
        redirect: '/*',
    },
    {
        path: '/products/events/*',
        redirect: '/products/tickets/*',
        props: true,
    },
    {
        name: 'root',
        path: '/',
        alias: '/en/',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['member', 'operator'],
            roleHome: {
                owner: '/dashboard',
                member: '/dashboard',
                operator: '/dashboard',
                'venue-manager': '/venues',
                editor: '/projects',
            },
        },
        props: true,
    },
    {
        name: 'dashboard',
        path: '/dashboard/:subview?',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['member', 'operator'],
        },
        props: true,
    },
    {
        name: 'signin',
        path: '/signin',
        alias: '/en/signin',
        component: () => import('@/views/Auth.vue'),
        meta: {
            redirectAuth: 'dashboard',
        },
    },
    {
        name: 'signup',
        path: '/signup',
        alias: '/en/signup',
        component: () => import('@/views/Auth.vue'),
        meta: {
            redirectAuth: 'dashboard',
        },
    },
    {
        name: 'signout',
        path: '/signout',
        alias: '/en/signout',
        meta: {
            authRequired: true,
        },
    },
    {
        name: 'reset',
        path: '/reset/:token?',
        alias: '/en/reset/:token?',
        component: () => import('@/views/Auth.vue'),
        props: true,
    },
    {
        name: 'auth',
        path: '/auth/:service/:op?',
        alias: '/en/auth/:service/:op?',
        component: () => import('@/views/Auth.vue'),
        props: true,
    },
    {
        name: 'account',
        path: '/account/:view?/:id?',
        alias: '/en/account/:view?/:id?',
        component: () => import('@/views/Account.vue'),
        meta: {
            authRequired: true,
        },
        props: true,
    },
    {
        name: 'billing',
        path: '/billing/:view?/:plan?',
        alias: '/en/billing/:view?/:plan?',
        component: () => import('@/views/Billing.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner'],
        },
        props: true,
    },
    {
        name: 'confirm',
        path: '/workspaces/confirm/:token',
        alias: '/en/workspaces/confirm/:token',
        component: () => import('@/views/ConfirmEmail.vue'),
        props: true,
    },
    {
        name: 'workspace-create',
        path: '/workspaces/create',
        alias: '/welcome',
        component: () => import('@/views/Workspaces.vue'),
        meta: {
            authRequired: true,
        },
        props: true,
    },
    {
        name: 'workspace-business-info',
        path: '/workspaces/business-info',
        component: () => import('@/views/Workspaces.vue'),
        meta: {
            authRequired: true,
        },
        props: true,
    },
    {
        name: 'workspaces',
        path: '/workspaces/:id?',
        alias: '/en/workspaces/:id?',
        component: () => import('@/views/Workspaces.vue'),
        meta: {
            authRequired: true,
        },
        props: true,
    },
    {
        name: 'deny-invite',
        path: '/workspace-invite/deny/:token?',
        alias: '/en/workspace-invite/deny/:token?',
        component: () => import('@/views/WorkspaceInvite.vue'),
        props: true,
    },
    {
        name: 'accept-invite',
        path: '/workspace-invite/accept/:token?',
        alias: '/en/workspace-invite/accept/:token?',
        component: () => import('@/views/WorkspaceInvite.vue'),
        props: true,
    },
    {
        name: 'contacts',
        path: '/contacts/:id?/:tab?',
        alias: '/en/contacts/:id?/:tab?',
        component: () => import('@/views/Contacts.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member'],
        },
        props: true,
    },
    {
        name: 'contactlist',
        path: '/contactlist/:listId',
        alias: '/en/contactlist/:listId',
        component: () => import('@/views/Contacts.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member'],
        },
        props: true,
    },
    {
        name: 'invoices',
        path: '/invoices/:id?',
        alias: '/en/invoices/:id?',
        component: () => import('@/views/Invoices.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member'],
        },
        props: true,
    },
    {
        name: 'transactions',
        path: '/transactions/:id?',
        alias: '/en/transactions/:id?',
        component: () => import('@/views/Transactions.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member'],
        },
        props: true,
    },
    {
        name: 'settings',
        path: '/settings/:subview?/:service?/:op?',
        alias: '/en/settings/:subview?/:service?/:op?',
        component: () => import('@/views/Settings.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member'],
        },
        props: true,
    },
    {
        path: '/products/:type',
        redirect: { name: 'projects' },
    },
    {
        name: 'products',
        path: '/products/:subview?/:id?/:tab?/:tabId?',
        alias: '/en/products/:subview?/:id?/:tab?/:tabId?',
        component: () => import('@/views/Products.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member', 'editor'],
        },
        props: true,
    },
    {
        name: 'createProject',
        path: '/create',
        alias: '/en/create',
        component: () => import('@/views/CreateProject.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member', 'editor'],
        },
        props: true,
    },
    {
        name: 'projects',
        path: '/projects/:tab?',
        alias: '/en/projects/:tab?',
        component: () => import('@/views/Projects.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member', 'editor'],
        },
        props: true,
    },
    {
        name: 'campaigns',
        path: '/campaigns/:id?',
        alias: '/en/campaigns/:id?',
        component: () => import('@/views/Campaign.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member', 'operator'],
        },
        props: true,
    },
    {
        name: 'venues',
        path: '/venues/:id?/:tab?',
        alias: '/en/venues/:id?/:tab?',
        component: () => import('@/views/Venue.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: '*',
        },
        props: true,
    },
    {
        name: 'designer',
        path: '/designer',
        component: () => import('@/views/Designer.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
        },
    },
    {
        name: 'kyc',
        path: '/kyc/:id?',
        alias: '/en/kyc/:id?',
        component: () => import('@/views/Kyc.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member', 'operator'],
        },
        props: true,
    },
    {
        name: 'square-signin',
        path: '/square/signin',
        alias: '/en/square/signin',
        component: () => import('@/views/Auth.vue'),
        meta: {
            redirectAuth: 'dashboard',
            roles: ['owner', 'member'],
        },
    },
    {
        name: 'square-signup',
        path: '/square/signup',
        alias: '/en/square/signup',
        component: () => import('@/views/Auth.vue'),
        meta: {
            redirectAuth: 'dashboard',
            roles: ['owner', 'member'],
        },
    },
    {
        name: 'square-integration',
        path: '/square-integration',
        alias: '/en/square-integration',
        component: () => import('@/views/SquareInegration.vue'),
        meta: {
            authRequired: true,
            workspaceRequired: true,
            roles: ['owner', 'member'],
        },
    },
    {
        name: '404',
        path: '/404',
        alias: '/en/404',
        component: () => import('@/views/404.vue'),
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
    {
        name: '500',
        path: '/500',
        component: () => import('@/views/500.vue'),
        props: true,
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
        path: '*',
        component: () => import('@/views/404.vue'),
    },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@/views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@/views/my-view')
//
// function lazyLoadView(AsyncView) {
//     const AsyncHandler = () => ({
//         component: AsyncView,
//         // A component to use while the component is loading.
//         loading: require('@/views/Loading').default,
//         // A fallback component in case the timeout is exceeded
//         // when loading the component.
//         error: require('@/views/Timeout').default,
//         // Delay before showing the loading component.
//         // Default: 200 (milliseconds).
//         delay: 400,
//         // Time before giving up trying to load the component.
//         // Default: Infinity (milliseconds).
//         timeout: 10000,
//     })
//
//     return Promise.resolve({
//         functional: true,
//         render(h, { data, children }) {
//             // Transparently pass any props or children to the view component.
//             return h(AsyncHandler, data, children)
//         },
//     })
// }
export default withRBAC(routes)
