import api from '@/api'
import pick from 'lodash/pick'
import omitBy from 'lodash/omitBy'

import { dataURLtoBlob } from '@/utils/data-url'

const state = {
    list: null,
}

const mutations = {
    RESET(state) {
        state.list = null
        state.passes = {}
        state.passes_sum = null
    },
}

const actions = {
    createBooking({ dispatch, commit }, project) {
        project.type = 'booking'
        return dispatch('createProject', project).then(data => {
            dispatch('getUser')
            return data
        })
    },
    updateBooking({ dispatch, commit }, project) {
        return dispatch('updateProject', project).then(data => {
            return data
        })
    },
    archiveBookingUnit({ dispatch }, id) {
        return api.patch(`/bookings/units/${id}/archive`).catch(e => dispatch('handleError', e))
    },
    unarchiveBookingUnit({ dispatch }, id) {
        return api.patch(`/bookings/units/${id}/unarchive`).catch(e => dispatch('handleError', e))
    },
    deleteBookingUnit({ dispatch }, id) {
        return api.delete(`/bookings/units/${id}`).catch(e => dispatch('handleError', e))
    },
    getBookingUnits({ dispatch }, id) {
        return api
            .get(`/bookings/${id}/units`)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    createBookingUnit({ dispatch }, { id, unit }) {
        return api
            .request({
                url: `/bookings/${id}/units`,
                method: 'post',
                data: { unit: serializeUnit(unit) },
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    updateBookingUnit({ dispatch }, { id, unit }) {
        return api
            .request({
                url: `/bookings/${id}/units/${unit.id}`,
                method: 'patch',
                data: { unit: serializeUnit(unit) },
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    updateBookingUnitImages({ dispatch }, { project, unit, data }) {
        if (
            !Object.keys(data || {}).length ||
            (data.images &&
                Object.keys(data).length === 1 &&
                !Object.keys(data?.images || {}).length)
        ) {
            return Promise.resolve()
        }

        const formData = new FormData()

        for (const key in data) {
            if (key === 'images') {
                const encodedImages = omitBy(
                    data.images,
                    v => !(typeof v == 'string' && v.indexOf('data:') === 0)
                )
                let images = Object.keys(encodedImages).reduce(
                    (images, key) => ({ ...images, [key]: dataURLtoBlob(encodedImages[key]) }),
                    {}
                )

                for (const key in images) {
                    if (images[key]) {
                        formData.append(`unit[images][${key}]`, images[key])
                    }
                }
            } else {
                formData.append(`unit[${key}]`, data[key])
            }
        }

        return api
            .request({
                url: `/bookings/${project.id}/units/${unit.id}/images`,
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },

    getBookings({ dispatch }, { id, show_past, venueId, unitId, itemsPerPage = 10, page, search }) {
        return api
            .get(`/bookings/${id}/list`, {
                params: { show_past, venue: venueId, unit: unitId, itemsPerPage, page, search },
            })
            .catch(e => dispatch('handleError', e))
    },
    completeBookingAppointment({ dispatch }, { id }) {
        return api
            .patch(`/bookings/reservations/${id}/complete`)
            .catch(e => dispatch('handleError', e))
    },
    confirmBookingAppointment({ dispatch }, { id }) {
        return api
            .patch(`/bookings/reservations/${id}/confirm`)
            .catch(e => dispatch('handleError', e))
    },
    cancelBookingAppointment({ dispatch }, { id }) {
        return api
            .delete(`/bookings/reservations/${id}/cancel`)
            .catch(e => dispatch('handleError', e))
    },
    createBookingAppointment({ dispatch }, { unit, reservation }) {
        return api
            .put(`/bookings/units/${unit}/make`, { reservation })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getBookingAvailability({ dispatch }, { id, unit = null }) {
        let params = unit ? { unit } : null
        return api
            .get(`/bookings/${id}/availability`, params ? { params } : null)
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    getBookingMatches({ dispatch }, { id, pagination }) {
        pagination ? delete pagination.totalItems : false

        return api
            .get(`/bookings/${id}/matches`, { params: pagination })
            .then(({ data }) => data)
            .catch(e => dispatch('handleError', e))
    },
    exportMatches({ dispatch }, id) {
        return api
            .get(`/bookings/${id}/matches/download`, {})
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
    exportBookingStats({ dispatch }, id) {
        return api
            .post(`/bookings/${id}/stats/download`, {})
            .then(({ location }) => location) // location - path to the created file.
            .catch(e => dispatch('handleError', e))
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
}

// omits all extra fields in request
function serializeUnit(unit) {
    const data = pick(unit, [
        'id',
        'name',
        'plans',
        'quantity',
        'capacity',
        'duration',
        'from',
        'buffer',
        'min_cancel_time',
        'venue',
        'amount',
        'design_id',
        'images',
        'pass_layout',
        'ios_logo_layout',
        'access_details',
        'tax_included',
        'availability_constraints',
        'access_details',
        'colors',
    ])

    return data
}
